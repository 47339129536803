import styled, { css } from '@emotion/core';

export const theme = {
  colors: {
    primary: '#6249D2',
    primaryLight: '#eff5ff',
    primaryLightBorder: '#c1d9ff',
    dark: '#464a56',
    white: '#ffffff',
    gray: '#F1F4F7',
    border: '#eceff2',
    blueGradient: ['#409bff', '#007aff'],
    darkGradient: ['#666C78', '#353C4C'],
    lightRed: '#F5E6EC',
    redText: '#cb5d5d',
    red: '#CE4554',
    darkGrayFill: '#F9F9FB',
    darkBorder: '#E3E7EB',
    orange: '#F0755E',
    purple: '#6249D2',
    maroon: '#A24771',
  },
  style: {
    xsBorderRadius: '6px',
    smBorderRadius: '8px',
    mdBorderRadius: '10px',
    lgBorderRadius: '12px',
    mdBoxShadow: 'rgba(44, 50, 66, 0.08) 0px 1px 2px 0px',
    lgBoxShadow: '0 2px 13px 0 rgba(83, 88, 102, 0.08)',
    mdBoxShadowBorder: '0.0325rem solid #f4f5f657',
  },
};

// background-image: linear-gradient(to top, #007aff, #409bff);

export const tileBoxShadow = css`
  border: 0.0325rem solid #f4f5f657;
  box-shadow: ${theme.style.mdBoxShadow};
  @media screen and (max-width: 800px) {
    border: none;
    box-shadow: ${theme.style.lgBoxShadow};
  }
`;

export const standardFeedTile = css`
  border: 0.0325rem solid #f4f5f657;
  box-shadow: ${theme.style.mdBoxShadow};
  border-radius: ${theme.style.lgBorderRadius};
  background: white;
  margin-bottom: 24px;
  @media screen and (max-width: 800px) {
    border: none;
    box-shadow: ${theme.style.lgBoxShadow};
    margin-bottom: 8px;
    border-radius: 0px;
  }
`;

export const standardStandaloneTile = css`
  border: 0.0325rem solid #f4f5f657;
  box-shadow: ${theme.style.mdBoxShadow};
  border-radius: ${theme.style.mdBorderRadius};
  background: white;
  @media screen and (max-width: 800px) {
    border: none;
    box-shadow: ${theme.style.lgBoxShadow};
    border-radius: 0px;
  }
`;

export const blueGradient = css`
  background: #6249d2;
`;

export const darkGradient = css`
  background: #4a515e;
`;

export const grayGradient = css`
  background-image: linear-gradient(to top, #838791, #a7aab1);
`;

export const redGradient = css`
  background: #ce4554;
`;

export const mercuryGradient = css`
  background-image: linear-gradient(to top, #baa7b5, #a4b0be);
`;

export const orangeGradient = css`
  background-image: linear-gradient(to top, #ff7a00, #ff9b3f);
`;

export const boxShadow = css`
  box-shadow: ${theme.style.lgBoxShadow};
`;

export const gridLayout = css`
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 325px 1fr;
  grid-gap: 30px;
  padding: 30px;
  max-width: 1160px;
  margin: 0 auto;
`;

export const headerOne = css`
  font-size: 26px;
  line-height: 32px;
  @media screen and (max-width: 800px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const headerTwo = css`
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const headerThree = css`
  font-size: 18px;
  line-height: 24px;
`;

export const headerFour = css`
  font-size: 15px;
  line-height: 21px;
  font-family: 'Light';
`;

export const paragraph = css`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Light';
  @media screen and (max-width: 800px) {
    font-size: 13px;
    line-height: 19px;
  }
`;

export const label = css`
  font-size: 14px;
  line-height: 19px;
`;

export const pageWrapper = css`
  width: 800px;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 30px;
  @media screen and (max-width: 800px) {
    width: 100vw;
    max-width: 100vw;
    padding: 8px 0px 0px 0px;
  }
`;

export const feedFooter = css`
  background: white;
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -20px -20px -20px;
  padding: 20px 30px;
  border-top: 1px solid ${theme.colors.border};
  width: calc(100% + 40px);
  border-radius: 0 0 ${theme.style.lgBorderRadius} ${theme.style.lgBorderRadius};
  z-index: 1;
  @media screen and (max-width: 800px) {
    margin: 0 -10px -10px -10px;
    padding: 20px;
    button {
      width: 100%;
    }
  }
`;

export const visibleScrollbar = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px !important;
    background: ${theme.colors.gray};
    border-radius: 10px;
    -webkit-overflow-scrolling: auto;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${theme.colors.dark + '50'};
    box-shadow: none;
  }
`;

export const mdBorderRadius = css`
  border-radius: ${theme.style.mdBorderRadius};
`;

export const lgBorderRadius = css`
  border-radius: ${theme.style.lgBorderRadius};
`;

export const floatSaveFooter = css`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: white;
  padding: 20px;
  ${mdBorderRadius};
  box-shadow: ${theme.style.mdBoxShadow};
  z-index: 2;
  @media screen and (max-width: 800px) {
    border-radius: 0px;
    border: none;
    border-top: 2px solid ${theme.colors.border};
    left: 0px;
    bottom: 60px;
    right: 0px;
    button {
      width: 100%;
    }
  }
`;
