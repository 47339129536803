import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProjectGrid from 'components/ProjectGrid';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { DEFAULT_TITLE } from 'helpers/data';
import { getProjectUrlFromId } from 'helpers/utils';

const ProjectBuckets = {
	New: 0,
	Current: 1,
	Archived: 2,
	Shared: 3,
}

function Main(props) {
  const { projects, refreshNavigationState, currentUser } = props;

  const projectBucket = (project) => {
    if (project.id === 'add-new-project') return ProjectBuckets.New;

    const isOwner = (project.user_id === currentUser.id);
    const archived = isOwner ? project.archived : project.share_archived;
    if (archived) return ProjectBuckets.Archived;

    if (isOwner) return ProjectBuckets.Current;

    return ProjectBuckets.Shared;
  }

  const [currentProjects, archivedProjects, sharedProjects] = projects.reduce((res, project) => {
    const bucketIndex = projectBucket(project) - 1;
    res[bucketIndex].push(project);
    return res;
  }, [[], [], []]);

  const { projectId } = useParams();
  const startingProject = (projectId && projects.find(project => project.id === parseInt(projectId))) || { id: 'add-new-project' }
  const [activeProject, setActiveProject] = useState(startingProject);

  const [currentDropdownIsOpen, setCurrentDropdownIsOpen] = useState(false);
  const toggleCurrentDropdownIsOpen = () => setCurrentDropdownIsOpen(!currentDropdownIsOpen);
  const [archivedDropdownIsOpen, setArchivedDropdownIsOpen] = useState(false);
  const toggleArchivedDropdownIsOpen = () => setArchivedDropdownIsOpen(!archivedDropdownIsOpen);
  const [sharedDropdownIsOpen, setSharedDropdownIsOpen] = useState(false);
  const toggleSharedDropdownIsOpen = () => setSharedDropdownIsOpen(!sharedDropdownIsOpen);

  const toggle = (tab) => {
    if (activeProject.id !== tab) setActiveProject((tab === 'add-new-project') ? { id: 'add-new-project' } : projects.find(project => project.id === tab));
  }

  return (
    <>
      <Nav tabs className="w-100">
        <Row className="w-100 mx-0">
          <Col className="px-0">
            <NavItem>
              <NavLink
                tag={Link}
                className={classnames({ active: projectBucket(activeProject) === ProjectBuckets.New })}
                onClick={() => { toggle('add-new-project'); }}
                to={`/`}
              >
                Add New Project
              </NavLink>
            </NavItem>
          </Col>
          <Col className="px-0">
            <Dropdown nav isOpen={currentDropdownIsOpen} toggle={toggleCurrentDropdownIsOpen}>
              <DropdownToggle nav caret className={classnames({ active: projectBucket(activeProject) === ProjectBuckets.Current })}>
                Current
              </DropdownToggle>
              <DropdownMenu>
                {currentProjects.map((project) => (
                  <DropdownItem
                    tag={Link}
                    className={classnames({ active: activeProject.id === project.id })}
                    onClick={() => { toggle(project.id); }}
                    to={getProjectUrlFromId(project.id)}
                  >
                    {project.title || DEFAULT_TITLE}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col className="px-0">
            <Dropdown nav isOpen={archivedDropdownIsOpen} toggle={toggleArchivedDropdownIsOpen}>
              <DropdownToggle nav caret className={classnames({ active: projectBucket(activeProject) === ProjectBuckets.Archived })}>
                Archived
              </DropdownToggle>
              <DropdownMenu>
                {archivedProjects.map((project) => (
                  <DropdownItem
                    tag={Link}
                    className={classnames({ active: activeProject.id === project.id })}
                    onClick={() => { toggle(project.id); }}
                    to={`/${project.id}`}
                  >
                    {project.title || DEFAULT_TITLE}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col className="px-0">
            <Dropdown nav isOpen={sharedDropdownIsOpen} toggle={toggleSharedDropdownIsOpen}>
              <DropdownToggle nav caret className={classnames({ active: projectBucket(activeProject) === ProjectBuckets.Shared })}>
                Shared
              </DropdownToggle>
              <DropdownMenu>
                {sharedProjects.map((project) => (
                  <DropdownItem
                    tag={Link}
                    className={classnames({ active: activeProject.id === project.id })}
                    onClick={() => { toggle(project.id); }}
                    to={`/${project.id}`}
                  >
                    {project.title || DEFAULT_TITLE}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </Nav>
      <TabContent activeTab={activeProject.id}>
        <TabPane tabId="add-new-project">
          <ProjectGrid setActiveProjectId={toggle} editable />
        </TabPane>
        {projects.map((project) => (
          <TabPane tabId={project.id}>
            <ProjectGrid
              project={project}
              archived={projectBucket(project) === ProjectBuckets.Archived}
              refreshNavigationState={refreshNavigationState}
              editable={project.user_id === currentUser.id || project.is_editor}
            />
          </TabPane>
        ))}
      </TabContent>
    </>
  );
}

export default Main;
