import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme, headerFour } from 'styles/theme';

const Styled = styled.div`
  padding: 25px;
  border-bottom: 1px solid ${theme.colors.border};
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`;

const Title = styled.div`
  ${headerFour};
  font-family: 'Medium';
`;

const Header = ({ selectedDay, selectedDayIndex }) => (
  <Styled>
    <Title>
      Day {selectedDayIndex + 1}: {selectedDay.title}
    </Title>
  </Styled>
);

export default Header;
