import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme, blueGradient, darkGradient, redGradient, boxShadow } from 'styles/theme';

const Styled = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: ${theme.colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  flex-shrink: 0;
  padding: 0px;
  touch-action: manipulation;
  ${({ size }) =>
    size &&
    css`
      height: ${size};
      width: ${size};
    `};
  ${({ mobileSize }) =>
    mobileSize.length < 8 &&
    css`
      @media screen and (max-width: 800px) {
        height: ${mobileSize};
        width: ${mobileSize};
      }
    `};
  ${({ useBlueGradient }) =>
    useBlueGradient &&
    css`
      ${blueGradient};
      color: white;
    `};
  ${({ useDarkGradient }) =>
    useDarkGradient &&
    css`
      ${darkGradient};
      color: white;
    `};
  ${({ useRedGradient }) =>
    useRedGradient &&
    css`
      ${redGradient};
      color: white;
    `};
  ${({ whiteBoxShadow }) =>
    whiteBoxShadow &&
    css`
      background: white;
      ${boxShadow};
    `};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `};
  ${({ iconColor }) =>
    iconColor &&
    css`
      color: ${iconColor};
    `};
  ${({ iconSize }) =>
    iconSize &&
    css`
      font-size: ${iconSize};
    `};
  ${({ border }) =>
    border &&
    css`
      border: ${border};
    `};
  ${({ gradient }) =>
    gradient &&
    css`
      ${gradient};
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `};
  ${({ noCursor }) =>
    noCursor &&
    css`
      cursor: text;
    `};
  ${({ disabled, visualDisabled }) =>
    disabled &&
    visualDisabled &&
    css`
      opacity: 0.5;
    `};
  ${({ useBorder }) =>
    useBorder &&
    css`
      border: 1px solid ${theme.colors.border};
    `};
`;

const CircleIcon = ({
  size,
  icon,
  useBlueGradient,
  useDarkGradient,
  useRedGradient,
  whiteBoxShadow,
  onClick,
  backgroundColor,
  iconColor,
  iconSize,
  border,
  style,
  className,
  gradient,
  mobileSize,
  disabled,
  visualDisabled,
  borderRadius,
  onMouseEnter,
  noCursor,
  useBorder,
}) => (
  <Styled
    size={`${size}px`}
    useBlueGradient={useBlueGradient}
    useDarkGradient={useDarkGradient}
    useRedGradient={useRedGradient}
    whiteBoxShadow={whiteBoxShadow}
    onClick={onClick}
    backgroundColor={backgroundColor}
    iconColor={iconColor}
    iconSize={`${iconSize}px`}
    style={style}
    border={border}
    className={className}
    gradient={gradient}
    mobileSize={`${mobileSize}px`}
    disabled={disabled}
    visualDisabled={visualDisabled}
    borderRadius={borderRadius}
    onMouseEnter={onMouseEnter}
    noCursor={noCursor}
    useBorder={useBorder}>
    {icon}
  </Styled>
);

export default CircleIcon;
