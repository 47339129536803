import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import { GoCheck } from 'react-icons/go';
import CircleIcon from 'components/CircleIcon';

const Styled = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0px;
    `};
`;

const Label = styled.div`
  margin-left: 8px;
  ${({ extraLabelSpacing }) =>
    extraLabelSpacing &&
    css`
      margin-left: 12px;
    `};
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
`;

const Checkbox = ({ label, checked, onClick, style, isSquare, extraLabelSpacing, size, noPadding, disabled, fontSize, className }) => (
  <Styled style={style} noPadding={noPadding} className={className}>
    <CircleIcon
      useBlueGradient={checked}
      size={size || 26}
      icon={<GoCheck size={14} />}
      border={!checked && `2px solid ${theme.colors.border}`}
      backgroundColor={!checked && 'white'}
      iconColor={!checked && theme.colors.white}
      onClick={onClick}
      borderRadius={isSquare && '6px'}
      disabled={disabled}
    />
    {!!label && (
      <Label extraLabelSpacing={extraLabelSpacing} fontSize={fontSize}>
        {label}
      </Label>
    )}
  </Styled>
);

export default Checkbox;
