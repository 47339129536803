import { fetchURL, subdomain } from 'helpers/constants';
import moment from 'moment';
import Cookies from 'js-cookie';

export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export function getCurrentUser(skipBeginning) {
  return dispatch => {
    if (!skipBeginning) dispatch(getCurrentUserBegin());
    return fetch(`${fetchURL}/api/v1/users/${subdomain}/get_current_user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: Cookies.get('id_token') },
      body: JSON.stringify({
        timezone_offset: moment().utcOffset(),
        timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(currentUser => {
        console.log('currentUser >>', currentUser);
        const isSignedIn = !currentUser.error;
        dispatch(getCurrentUserSuccess(currentUser, isSignedIn));
        return currentUser;
      })
      .catch(error => dispatch(getCurrentUserFailure(error)));
  };
}

export function updateUserNotices(key, value) {
  const userNotices = { [key]: value };
  return dispatch => {
    return fetch(`${fetchURL}/api/v1/users/update_user_notices`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: Cookies.get('id_token') },
      body: JSON.stringify({ user_notices: userNotices }),
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(data => {
        return { success: true };
      });
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const getCurrentUserBegin = () => ({
  type: GET_CURRENT_USER_BEGIN,
});

export const getCurrentUserSuccess = (currentUser, isSignedIn) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: { currentUser, isSignedIn },
});

export const getCurrentUserFailure = error => ({
  type: GET_CURRENT_USER_FAILURE,
  payload: { error },
});

const DEFAULT_STATE = {
  currentUser: {},
  isSignedIn: false,
  currentUserLoaded: false,
};

export const currentUserReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_BEGIN:
      return Object.assign({}, state, { currentUser: {}, isSignedIn: false, currentUserLoaded: false });
    case GET_CURRENT_USER_SUCCESS:
      return Object.assign({}, state, {
        currentUser: action.payload.currentUser,
        isSignedIn: action.payload.isSignedIn,
        currentUserLoaded: true,
      });
    case GET_CURRENT_USER_FAILURE:
      return Object.assign({}, state, { currentUser: {}, isSignedIn: false, currentUserLoaded: true });
    default:
      return state;
  }
};
