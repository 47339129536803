import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import reducer from 'reduxx/mainReducer';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Navigation from 'containers/Navigation';

const store = createStore(reducer, applyMiddleware(thunk));

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Route path="/:projectId?" component={Navigation} />
        </Router>
      </Provider>
    );
  }
}

export default App;

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

const appHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', appHeight);
appHeight();
