import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme, blueGradient, darkGradient, redGradient, mdBorderRadius } from 'styles/theme';

const Styled = styled.button`
  padding: 16px 20px;
  background: ${theme.colors.gray};
  border: none;
  ${mdBorderRadius};
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  touch-action: manipulation;
  ${({ mdButton }) =>
    mdButton &&
    css`
      font-size: 13px;
      padding: 14px 16px;
    `};
  ${({ mdPadding }) =>
    mdPadding &&
    css`
      padding: 14px 16px;
    `};
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: auto;
    `};
  ${({ useBoxShadow }) =>
    useBoxShadow &&
    css`
      background: white;
      border: ${theme.style.mdBoxShadowBorder};
      box-shadow: ${theme.style.mdBoxShadow};
      &:focus {
        border: ${theme.style.mdBoxShadowBorder} !important;
        box-shadow: ${theme.style.mdBoxShadow} !important;
      }
    `};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `};
  ${({ border }) =>
    border &&
    css`
      border: ${border};
    `};
  ${({ useBlueGradient }) =>
    useBlueGradient &&
    css`
      ${blueGradient};
      color: white;
    `};
  ${({ useDarkGradient }) =>
    useDarkGradient &&
    css`
      ${darkGradient};
      color: white;
    `};
  ${({ useRedGradient }) =>
    useRedGradient &&
    css`
      ${redGradient};
      color: white;
    `};
  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `};
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width}%;
    `};
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
  ${({ lightText }) =>
    lightText &&
    css`
      font-family: 'Light';
    `};
  ${({ noEllipse }) =>
    noEllipse &&
    css`
      display: flex;
    `};
  ${({ noShrink }) =>
    noShrink &&
    css`
      flex-shrink: 0;
    `};
  ${({ round }) =>
    round &&
    css`
      border-radius: 50px;
    `};
  ${({ allowOverflow }) =>
    allowOverflow &&
    css`
      overflow: visible;
      white-space: pre-wrap;
    `};
  ${({ useBorder }) =>
    useBorder &&
    css`
      border: 1px solid ${theme.colors.border};
    `};
  background-origin: border-box;
`;

const Button = ({
  text,
  style,
  onClick,
  useBlueGradient,
  useDarkGradient,
  useRedGradient,
  backgroundColor,
  border,
  textColor,
  fontSize,
  autoWidth,
  padding,
  width,
  borderRadius,
  disabled,
  id,
  className,
  preIcon,
  icon,
  lightText,
  noEllipse,
  noShrink,
  round,
  allowOverflow,
  useBorder,
  useBoxShadow,
  mdButton,
  mdPadding,
}) => (
  <Styled
    style={style}
    useBlueGradient={useBlueGradient}
    useDarkGradient={useDarkGradient}
    useRedGradient={useRedGradient}
    backgroundColor={backgroundColor}
    border={border}
    textColor={textColor}
    fontSize={fontSize}
    autoWidth={autoWidth}
    padding={padding}
    onClick={onClick}
    width={width}
    borderRadius={borderRadius}
    disabled={disabled}
    id={id}
    className={className}
    lightText={lightText}
    noEllipse={noEllipse}
    noShrink={noShrink}
    round={round}
    allowOverflow={allowOverflow}
    useBorder={useBorder}
    useBoxShadow={useBoxShadow}
    mdButton={mdButton}
    mdPadding={mdPadding}>
    {preIcon}
    {text}
    {icon}
  </Styled>
);

export default Button;
