import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import Button from 'components/Button';

const Styled = styled.div`
  position: sticky;
  bottom: 0px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 25px;
  border-top: 1px solid ${theme.colors.border};
  z-index: 1;
  @media screen and (max-width: 800px) {
    padding: 20px;
    button {
      width: 50%;
    }
  }
`;

const Footer = ({ onApplyFilters, onClearFilters }) => (
  <Styled>
    <Button text="Cancel" style={{ marginRight: 12 }} autoWidth onClick={onClearFilters} mdButton />
    <Button text="Save" useBlueGradient autoWidth onClick={onApplyFilters} mdButton />
  </Styled>
);

export default Footer;
