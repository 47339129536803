import { fetchURL } from 'helpers/constants';
import React, { useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";

const ShareModal = ({ projectId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [editAllowed, setEditAllowed] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    setEmail('');
    setErrors([]);
    setSuccess(false);
  }, [isOpen])

  const shareProject = () => {
    setErrors([]);
    setSuccess(false);

    fetch(`${fetchURL}/api/v1/projects/${projectId}/share`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: window.localStorage.getItem('id_token') },
      body: JSON.stringify({ email, editAllowed }),
    })
      .then(response => {
        if (response.ok) {
          setErrors([]);
          setSuccess(true);
        } else {
          response.json().then(data => {
            const newErrors = (!!data.errors) ? data.errors : ['something went wrong'];
            setErrors([newErrors]);
            setSuccess(false);
          })
        }
      })
  }

  return (
    <>
      <Button id="share-button" outline size="sm" color="link" onClick={() => setIsOpen(true)}>Share</Button>
      <UncontrolledTooltip placement="top" target="share-button" delay={{ show: 0, hide: 0 }}>Shares this Project with a Co-Teacher, Admin, and/or Korda Institute to view only or edit</UncontrolledTooltip>

      <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Share Project</ModalHeader>
        <ModalBody>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            name="email"
            type="email"
            invalid={errors.length > 0}
            valid={success}
          />
          {(errors.length > 0) && (
            <FormFeedback invalid>{errors}</FormFeedback>
          )}
          {success && (
            <FormFeedback valid>Project shared!</FormFeedback>
          )}
          <FormGroup check className="pt-3">
            <Label check className="align-middle">
              <Input type="checkbox" value={editAllowed} onChange={e => setEditAllowed(e.target.checked)} />{' '}
              Allow this user to edit this project
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => shareProject()}>Share</Button>
          <Button color="secondary" onClick={() => setIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ShareModal;