import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import { fetchURL } from 'helpers/constants';
import logo from 'images/logo_h.png';
import Main from 'pages/Main';
import Join from './Join';

const Styled = styled.div`
  width: 800px;
  max-width: calc(100vw - 60px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
`;

const Header = styled.nav`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
`;

const Logo = styled.img`
  width: 120px;
`;

const HeaderText = styled.div`
  font-size: 13px;
  font-style: italic;
  font-family: 'Light';
  text-align: right;
  ${({ isLogout }) =>
    isLogout &&
    css`
      color: ${theme.colors.primary};
      opacity: 1;
      cursor: pointer;
    `};
`;

const DaysHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: ${theme.colors.gray};
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 2px;
  border-radius: 10px 10px 0 0;
`;

const WelcomeHeader = styled.div`
  font-size: 13px;
  div {
    font-family: 'Light';
    font-size: 11px;
    margin-top: 2px;
  }
`;

class Navigation extends React.Component {
  state = {};

  componentDidMount() {
    fetch(`${fetchURL}/api/v1/users/get_current_user`, {
      headers: { Authorization: window.localStorage.getItem('id_token') },
    })
      .then(response => response.json())
      .then(data => {
        if (!!data.errors) {
          this.setState({ isSignedIn: false, didLoad: true });
        } else {
          const stateToSet = { isSignedIn: true, currentUser: data, didLoad: true, projects: [] };

          this.loadProjects(stateToSet);
        }
      });
  }

  loadProjects = (stateToSet = {}) => {
    return fetch(`${fetchURL}/api/v1/projects`, {
      headers: { Authorization: window.localStorage.getItem('id_token') },
    })
      .then(response => response.json())
      .then(data => {
        if (!data.errors) {
          stateToSet.projects = data;
        }

        this.setState(stateToSet);
      });
  }

  onEmailChange = email => this.setState({ loginEmail: email });

  onLoginSubmit = () => {
    this.setState({ isLoginLoading: true });
    fetch(`${fetchURL}/api/v1/users/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: this.state.loginEmail }),
    })
      .then(response => response.json())
      .then(data => {
        window.localStorage.setItem('id_token', data.token);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
  };

  onLogoutSubmit = () => {
    let didConfirm = window.confirm('Are you sure you want to log out?');
    if (didConfirm) {
      window.localStorage.removeItem('id_token');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  render() {
    const { didLoad, isSignedIn, currentUser, loginEmail, isLoginLoading, projects } = this.state;
    if (!didLoad) return null;
    return (
      <Styled>
        <Header>
          <Logo src={logo} />
          {!isSignedIn && <HeaderText>Not Signed In</HeaderText>}
          {isSignedIn && (
            <div>
              <HeaderText>{currentUser.email}</HeaderText>
              <HeaderText isLogout onClick={this.onLogoutSubmit}>
                Logout
              </HeaderText>
            </div>
          )}
        </Header>

        <DaysHeader>
          <WelcomeHeader>
            Project Blocking Tool
            <div>
              This tool is meant to help you block out your project and plan daily activities, lessons and assignments. We have
              pre-populated each day with some suggestions to be helpful. We expect you will edit, add, move and adjust as you see fit for
              your specific students, projects and class.
            </div>
          </WelcomeHeader>
          <StyledStages>
            <Stage color="orange" title="Stage 1: Problem Definition" isFirst />
            <Stage color="maroon" title="Stage 2: Solution Hypothesis" />
            <Stage color="purple" title="Stage 3: Solution Development" />
          </StyledStages>
        </DaysHeader>

        {isSignedIn ? (
          <Main projects={projects} refreshNavigationState={() => this.forceUpdate()} currentUser={currentUser} />
        ) : (
          <Join
            loginEmail={loginEmail}
            onEmailChange={this.onEmailChange}
            onLoginSubmit={this.onLoginSubmit}
            isLoginLoading={isLoginLoading}
          />
        )}
      </Styled>
    );
  }
}

const StyledStages = styled.div`
  background: ${theme.colors.gray};
  padding-left: 20px;
  font-size: 13px;
  border-radius: 0 0 10px 10px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const StyledStage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 13px;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    margin-top: 16px;
    margin-left: 0px;
  }
  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-top: 0px;
      @media screen and (max-width: 800px) {
        margin-top: 0px;
      }
    `};
`;

const StageCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 4px;
  flex-shrink: 0;
  ${({ color }) =>
    color === 'orange' &&
    css`
      background: ${theme.colors.orange};
    `};
  ${({ color }) =>
    color === 'maroon' &&
    css`
      background: ${theme.colors.maroon};
    `};
  ${({ color }) =>
    color === 'purple' &&
    css`
      background: ${theme.colors.purple};
    `};
`;

const Stage = ({ color, title, isFirst }) => (
  <StyledStage isFirst={isFirst}>
    <StageCircle color={color} />
    {title}
  </StyledStage>
);

export default Navigation;
