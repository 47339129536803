import { subdomain, domain, protocol, baseURL } from 'helpers/constants';
// import moment from 'moment-timezone';

export const getWeekOfYearNumber = d => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNo;
};

export const stringToHTML = str => {
  var dom = document.createElement('div');
  dom.innerHTML = str;
  return dom;
};

export const numberToString = number => {
  if (number === 0) return 'zero';
  if (number === 1) return 'one';
  if (number === 2) return 'two';
  if (number === 3) return 'three';
  if (number === 4) return 'four';
  if (number === 5) return 'five';
  if (number === 6) return 'six';
  if (number === 7) return 'seven';
  if (number === 9) return 'nine';
  if (number === 10) return 'ten';
  if (number === 11) return 'eleven';
  if (number === 12) return 'twelve';
};

export const getTodayNumberLocal = () => {
  const hourNum = new Date().getHours();
  const hourStr = hourNum < 10 ? '0' + hourNum : String(hourNum);
  const todayNum = new Date().getDay() + '.' + hourStr;
  return +todayNum;
};

export const isToday = date => {
  let compareDate = new Date(date);
  let today = new Date();
  if (compareDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) return true;
  return false;
};

export const wasYesterday = date => {
  let compareDate = new Date(date);
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (compareDate.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)) return true;
  return false;
};

// export const getTodayNumberEST = () => {
//   const mom = moment;
//   const hourNum = mom()
//     .tz('America/New_York')
//     .hours();
//   const hourStr = hourNum < 10 ? '0' + hourNum : String(hourNum);
//   const todayNum =
//     mom()
//       .tz('America/New_York')
//       .day() +
//     '.' +
//     hourStr;
//   return +todayNum;
// };

export const getTodayNumberUTC = () => {
  const hourNum = new Date().getUTCHours();
  const hourStr = hourNum < 10 ? '0' + hourNum : String(hourNum);
  const todayNum = new Date().getUTCDay() + '.' + hourStr;
  return +todayNum;
};

export const getMonday = d => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  let returnDay = new Date(d.setDate(diff));
  return new Date(returnDay.setHours(0, 0, 0, 0));
};

export const getURLParamsValue = param => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getProjectUrlFromId = projectId => `/${projectId}`

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const redirectToJoin = () => {
  window.location.href = `${protocol}//app.${baseURL}`;
};

export const getNumbersArray = (start = 0, end = 21) => {
  let returnArr = [];
  for (var i = start; i < end; i++) {
    returnArr.push(i);
  }
  return returnArr;
};

export const moveElementInArray = (array, oldIndex, newIndex) => {
  if (newIndex === -1 || newIndex === array.length) return array;
  if (newIndex >= array.length) {
    var k = newIndex - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array; // for testing
};
