import moment from 'moment-timezone';
export const proto = window.location.protocol;
export const subdomain = window.location.host.split('.')[0];
export const domain = window.location.host.split('.')[1];
export const topLevel = window.location.host.split('.')[2];
export const pathName = window.location.pathname;

export const timezoneAbbreviation = moment.tz([2012, 0], moment.tz.guess()).format('z');

export const protocol = domain === 'lvh' ? 'https:' : 'https:';

export const fetchURL = (() => process.env.REACT_APP_FETCH_URL || 'http://localhost:3000')();

export const baseURL = (() => {
  if (domain === 'lvh') {
    return 'lvh.me:3006';
  } else {
    return 'meetsy.io';
  }
})();
