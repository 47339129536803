import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import { HiPlus, HiTrash } from 'react-icons/hi';
import { GoPlus } from 'react-icons/go';
import Checkbox from 'components/Checkbox';
import CircleIcon from 'components/CircleIcon';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { MdReorder } from 'react-icons/md';
import { Col, Input, Row } from 'reactstrap';
import { ACTIVITY_CODE_URL_MAP, TEACHER_TOOL_CODE_URL_MAP } from 'helpers/data';
import { useEffect } from 'react';

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Section = styled.div`
  margin-top: 24px;
`;

const TaskTile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .delete {
    z-index: 1;
    cursor: pointer;
  }
  transition: all 0.5s ease;
  &:hover {
    .delete {
      opacity: 1;
    }
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .text {
    font-size: 13px;
    color: ${theme.colors.primary};
    margin-left: 8px;
  }
`;

const TaskSection = ({
  category,
  tasks,
  onToggleTaskComplete,
  onTaskInputEdit,
  newTaskValue,
  onNewTaskInputChange,
  onAddNewTask,
  onDeleteTask,
  onReorderTask,
  disabled,
}) => {
  return (
    <Section>
      <Label>{category}</Label>
      <SortableTaskList
        tasks={tasks}
        category={category}
        onSortEnd={onReorderTask}
        axis="xy"
        distance={1}
        onToggleTaskComplete={onToggleTaskComplete}
        onTaskInputEdit={onTaskInputEdit}
        onDeleteTask={onDeleteTask}
        disabled={disabled}
      />
      {!disabled && (
        <AddButton onClick={() => onAddNewTask(category)}>
          <CircleIcon
            icon={<GoPlus size={12} />}
            size={20}
            borderRadius="6px"
            backgroundColor={theme.colors.primary + '15'}
            iconColor={theme.colors.primary}
          />
          <div className="text">Add</div>
        </AddButton>
      )}
    </Section>
  );
};

const resource_url = ({ activity_code, teacher_tool_code }) => activity_code ? ACTIVITY_CODE_URL_MAP[activity_code] : (teacher_tool_code && TEACHER_TOOL_CODE_URL_MAP[teacher_tool_code])

const SortableItem = SortableElement(({ value }) => <>{value}</>);

export const minTextareaHeight = '1.25em';

export const autoGrowTextareaHeight = (element, expand=true) => {
  element.style.height = minTextareaHeight;
  if (expand) element.style.height = (element.scrollHeight)+"px";
}

const SortableTaskList = SortableContainer(({ tasks, category, onToggleTaskComplete, onTaskInputEdit, onDeleteTask, disabled }) => {
  useEffect(() => {
    document.querySelectorAll('.js-textarea-autogrow').forEach((textarea) => {
      autoGrowTextareaHeight(textarea);
    })
  }, [tasks]);

  return (
    <div>
      {tasks.map((task, index) => {
        if (task.category !== category) return null;
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={<TaskTile>
              <Row className="w-100 mx-0">
                {!disabled && (
                  <Col className="flex-grow-0 px-0">
                    <MdReorder className="pr-2" />
                  </Col>
                )}
                <Col className="flex-grow-0 px-0">
                  <Checkbox
                    checked={task.is_complete}
                    disabled={disabled}
                    onClick={() => onToggleTaskComplete(index)}
                    className="py-0 pe-0 ps-1"
                    isSquare
                    size={20}
                  />
                </Col>
                <Col>
                  <Input
                    value={task.title}
                    onChange={e => { onTaskInputEdit(index, e.target.value); autoGrowTextareaHeight(e.target); }}
                    placeholder="New task..."
                    fullWidth
                    name="task"
                    type="textarea"
                    style={{
                      padding: 0, marginLeft: 8, borderRadius: 0,
                      border: 'none',
                      fontSize: '13px',
                      resize: 'none',
                      overflow: 'hidden',
                      height: minTextareaHeight,
                      minHeight: minTextareaHeight,
                    }}
                    className="js-textarea-autogrow bg-white"
                    disabled={disabled}
                  />
                </Col>
                {(resource_url(task)) && (
                  <Col className="flex-grow-0 px-0">
                    <a href={resource_url(task)}>PDF</a>
                  </Col>
                )}
                {!disabled && (
                  <Col className="flex-grow-0 px-0">
                    <CircleIcon
                      icon={<HiTrash size={12} />}
                      size={20}
                      borderRadius="6px"
                      className="delete ms-1"
                      backgroundColor={theme.colors.lightRed}
                      iconColor={theme.colors.red}
                      onClick={() => onDeleteTask(index)}
                    />
                  </Col>
                )}
              </Row>
            </TaskTile>}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
});


export default TaskSection;
