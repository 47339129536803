import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import Modal from 'components/Modal';
import { Button, Input } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import TaskSection, { autoGrowTextareaHeight, minTextareaHeight } from './TaskSection';

export const Styled = styled.div`
  background: white;
  border-radius: ${theme.style.mdBorderRadius};
  width: 500px;
  max-width: 100vw;
  max-height: calc(100vh - 60px);
  max-height: calc((var(--vh, 1vh) * 100) - 60px);
  overflow-y: auto;
`;

export const Body = styled.div`
  padding: 25px;
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Section = styled.div`
  margin-top: 20px;
`;

const DayModal = ({
  selectedDay,
  onInputChange,
  selectedDayIndex,
  onClose,
  onToggleTaskComplete,
  onTaskInputEdit,
  onAddNewTask,
  onDeleteTask,
  onDayInputChange,
  onReorderTask,
  disabled,
}) => {
  const [notesExpanded, setNotesExpanded] = useState(false);
  useEffect(() => {
    document.querySelectorAll('.js-notes-textarea').forEach((textarea) => {
      autoGrowTextareaHeight(textarea, notesExpanded);
    })
  }, [selectedDay.notes, notesExpanded]);

  return (
    <Modal isVisible onClose={onClose} shouldAlignBottom={window.innerWidth < 800}>
      <Styled>
        <Body>
          <Label>Day Title</Label>
          <Input
            value={selectedDay.title}
            onChange={e => onDayInputChange('title', e.target.value)}
            placeholder="Day title..."
            fullWidth
            name="title"
            type="text"
            wrapperPadding="0px"
            fontSize="13px"
            disabled={disabled}
          />

          <Label className="mt-2">My Notes <Button color="link" onClick={() => setNotesExpanded(notesExpanded => !notesExpanded)}>See {notesExpanded ? 'less' : 'more'}</Button></Label>
          <Input
            value={selectedDay.notes}
            onChange={e => onDayInputChange('notes', e.target.value)}
            placeholder="Project notes..."
            fullWidth
            name="notes"
            type="textarea"
            style={{
              resize: 'none',
              height: notesExpanded ? null : minTextareaHeight
            }}
            fontSize="13px"
            disabled={disabled}
            className="js-notes-textarea"
          />

          {['Before Class', 'During Class', 'After Class'].map(category => (
            <TaskSection
              key={category}
              category={category}
              tasks={selectedDay.tasks}
              onToggleTaskComplete={onToggleTaskComplete}
              onTaskInputEdit={onTaskInputEdit}
              onAddNewTask={onAddNewTask}
              onDeleteTask={onDeleteTask}
              onReorderTask={onReorderTask}
              disabled={disabled}
            />
          ))}
        </Body>
      </Styled>
    </Modal>
  );
};

export default DayModal;
