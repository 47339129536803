export const DEFAULT_DAYS = [
  {
    tasks: [
      {
        title: 'On-Ramp Activities: Fever Pitch',
        category: 'Before Class',
        is_complete: false,
        activity_code: 4,
      },
      {
        title: 'On-Ramp Activities: Presenting Well: Ad Activity',
        category: 'Before Class',
        is_complete: false,
        activity_code: 14,
      },
      {
        title: 'Tips for Teams',
        category: 'Before Class',
        is_complete: false,
        teacher_tool_code: 8,
      },
      {
        title: 'Sample Rubric',
        category: 'Before Class',
        is_complete: false,
        teacher_tool_code: 9,
      },
      {
        title: 'Field Trip to Receive Problem from Community Partner',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Students are Assigned to Teams',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Team Share Process',
        category: 'During Class',
        is_complete: false,
        activity_code: 2,
      },
      {
        title: 'Activity 1: Student Observations',
        category: 'During Class',
        is_complete: false,
        activity_code: 1,
      },
      {
        title: 'Activity 2: Solution Outline',
        category: 'During Class',
        is_complete: false,
        activity_code: 1,
      },
      {
        title: 'Activity 3: Research Plan',
        category: 'During Class',
        is_complete: false,
        activity_code: 1,
      },
      {
        title: 'Self-Assigned Research',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection(s): How Did Process Help My Team Today?',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Partner Gives Students the Problem',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'Teammates Share Research',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Class Reflection: What Went Well During Research Share',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Students Begin to Unpack How to Design Process for Teamwork',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: '1st News Assigned',
        category: 'After Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Team Research',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflections: Takeaways from Collaborating Today',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Uncovering Problem Complexity',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Jigsaw Activity',
        category: 'During Class',
        is_complete: false,
        activity_code: 5,
      },
      {
        title: 'Announce Upcoming Shareout Presentations',
        category: 'During Class',
        is_complete: false,
        activity_code: 6,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team research',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: "Individual Reflection(s): Articulating the Problem's Complexity",
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Problem Analysis Skill-Building',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Teams Continue Researching the Problem',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Teams Prepare Shareout Presentations',
        category: 'During Class',
        is_complete: false,
        activity_code: 6,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team research',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection Question(s)',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Distilling Research & Planning Next Steps',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Shareout Presentations',
        category: 'During Class',
        is_complete: false,
        activity_code: 6,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Research the Armful of Questions Received in Shareout',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection(s): My Biggest Takeaways from Shareout',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Getting Teacher Guidance',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Workshop: How to Interview Well',
        category: 'During Class',
        is_complete: false,
        activity_code: 7,
      },
      {
        title: 'Teams Create Interview Questions for Next Day Field Research',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Assigned Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection Question(s)',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Planning Field Research',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Field Research: Interviews in the Community',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Teams Gather Findings from Interviews',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Assigned Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection Question(s)',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Interviewing!',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Empathy Mapping Activity',
        category: 'During Class',
        is_complete: false,
        activity_code: 8,
      },
      {
        title: 'Reflection About Centering Solution Design on Human Needs',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection(s): Focus on Why & How Interviewing Matters',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Defining Personas & Segments',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Decision Filter Activity',
        category: 'During Class',
        is_complete: false,
        activity_code: 9,
      },
      {
        title: 'Prep for Second Shareout',
        category: 'During Class',
        is_complete: false,
        activity_code: 6,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection(s): Unpacking Decision Filer Impact',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Deciding Solution Hypotheses',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Second Shareout Presentations',
        category: 'During Class',
        is_complete: false,
        activity_code: 6,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Work Resulting From Feedback Received in Shareout',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection(s): My Biggest Takeaways from Shareout',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: "Discovering What's Best",
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Share & Discuss Roadmap of Schedule Leading Up to Finals',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: "Strengths Activity: Inventory Teammates' Strengths",
        category: 'During Class',
        is_complete: false,
        activity_code: 10,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection(s): Between Now & Finals, What Does My Team Most Need to Do? How and Why?',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Assessing What Matters Most',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Field Research Round 2',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Team Time to Develop Solutions',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Big Idea Rapid Exercise',
        category: 'During Class',
        is_complete: false,
        activity_code: 11,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection Question(s)',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Capturing Important Data',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Workshop: How to Present Well',
        category: 'During Class',
        is_complete: false,
        activity_code: 13,
      },
      {
        title: 'Presentation Role Play',
        category: 'During Class',
        is_complete: false,
        activity_code: 12,
      },
      {
        title: 'Teams Work on Solutions for Final Presentations',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Team Work',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection Question(s): Good Storytelling: What Do I Think is Most Important to Do?',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Storytelling',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'News Circle',
        category: 'During Class',
        is_complete: false,
        activity_code: 3,
      },
      {
        title: 'Teams Work to Develop Solution for Final Presentation',
        category: 'During Class',
        is_complete: false,
        activity_code: 15,
      },
      {
        title: 'News',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Presentation for Finals Tomorrow!',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Strategic Thinking & Design',
    is_active: true,
  },
  {
    tasks: [
      {
        title: 'Confirm Community Partner Will Arrive 15 Mins Early',
        category: 'Before Class',
        is_complete: false,
      },
      {
        title: 'Teams Present their Solutions to the Community Partner',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Question & Answer Session with Teams',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'Questions to Class',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: 'After Partner Leaves, Teams Share What They Did Best',
        category: 'During Class',
        is_complete: false,
      },
      {
        title: "Team Reflections and Feedback for Teammates (What's Best)",
        category: 'After Class',
        is_complete: false,
        activity_code: 16,
      },
      {
        title: 'Individual Student Feedback Session',
        category: 'After Class',
        is_complete: false,
      },
      {
        title: 'Individual Reflection: What I Did Best',
        category: 'After Class',
        is_complete: false,
      },
    ],
    title: 'Giving Creative Solutions to a Community Problem',
    is_active: true,
  },
];

export const DEFAULT_TITLE = '[Project Title]'

export const ACTIVITY_CODE_URL_MAP = {
  1: 'https://drive.google.com/file/d/17wP-9wme5Yne7AlnUU-LKjI7NuYvd21R/view',
  2: 'https://drive.google.com/file/d/1N33kPqEWuKz-9GiYpfRzeo_oK3Sx--Xy/view',
  3: 'https://drive.google.com/file/d/15o5_F45yvMJVsL5XtJL7EMfvRk9aCKsT/view',
  4: 'https://drive.google.com/file/d/1HcqDYgnSfn3B2cHalFnRWn4y4QiwUZz0/view',
  5: 'https://drive.google.com/file/d/1aTYwMh-mwRcorSUEREvz82FQeCL-xy3J/view',
  6: 'https://drive.google.com/file/d/1xeYFJ1Wee0bXyUi-4ylw5dfG4PMCd620/view',
  7: 'https://drive.google.com/file/d/1UfjWfLRniHC3AY91xVzf_e_0OorLjRP5/view',
  8: 'https://drive.google.com/file/d/1ThjhSMFy1IDAz3PdupmLEsRcQDcmRP9i/view',
  9: 'https://drive.google.com/file/d/1C5ZptRfyGdJJt6dN2RBO0QXii7HhbZnP/view',
  10: 'https://drive.google.com/file/d/1cIO9-zC7j-FOSBTWpxhmn4A9QC69EOIl/view',
  11: 'https://drive.google.com/file/d/1NU0HlIeo3fFM_fm91ZF9Aa1WPosot9xl/view',
  12: 'https://drive.google.com/file/d/152ZJ1vXIoK960dwVrU__hmfuCcXix1l1/view',
  13: 'https://drive.google.com/file/d/1sqRDzmSyOnY5LI-O3AHhFQjdUf9Hs956/view',
  14: 'https://drive.google.com/file/d/1PGAcCdlSCYs03DaIlxdSo0dKKl3GVZzx/view',
  15: 'https://drive.google.com/file/d/1qsEFlZMkVD4DCM4eWw2A5yPLBkq6fH2Q/view',
  16: 'https://drive.google.com/file/d/1XN6dYarSzwecRY3QnaMeQfi9QvxoQ1aH/view',
}

export const TEACHER_TOOL_CODE_URL_MAP = {
  8: 'https://drive.google.com/file/d/1zqmHnaowE9uYxrl12vuXE37zbc9j9-zE/view?usp=sharing',
  9: 'https://drive.google.com/file/d/1xA_PW1kV3RutlxMIBeyDX4P7dZ3dWXjC/view?usp=sharing',
}