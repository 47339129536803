import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme, mdBorderRadius } from 'styles/theme';

const Wrapper = styled.div`
  padding: 8px;
  display: inline-block;
  box-sizing: border-box;
  ${({ halfWidth }) =>
    halfWidth &&
    css`
      width: 50%;
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ wrapperPadding }) =>
    wrapperPadding &&
    css`
      padding: ${wrapperPadding};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`;

const InputElement = styled.input`
  position: relative;
  height: 50px;
  padding: 0px 16px;
  box-sizing: border-box;
  ${mdBorderRadius};
  border: 1px solid ${theme.colors.border};
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `};
  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `};
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
  ${({ useBoxShadow }) =>
    useBoxShadow &&
    css`
      border: ${theme.style.mdBoxShadowBorder};
      box-shadow: ${theme.style.mdBoxShadow};
      &:focus {
        border: ${theme.style.mdBoxShadowBorder} !important;
        box-shadow: ${theme.style.mdBoxShadow} !important;
      }
    `};
`;

const Input = props => (
  <Wrapper
    halfWidth={props.halfWidth}
    fullWidth={props.fullWidth}
    className={props.wrapperClassName}
    wrapperPadding={props.wrapperPadding}
    width={props.width}>
    <InputElement {...props} data-hj-allow />
  </Wrapper>
);

export default Input;
