import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import { BiMove, BiTrash, BiPlusCircle } from 'react-icons/bi';

const Wrapper = styled.div`
  position: relative;
  padding: 1px;
  width: 20%;
  .move-handle {
    opacity: 0;
    transition: all 0.5s ease;
  }
  &:hover {
    .move-handle {
      opacity: 1;
    }
  }
  @media screen and (max-width: 800px) {
    width: 25%;
  }
  @media screen and (max-width: 675px) {
    width: 33%;
  }
  @media screen and (max-width: 575px) {
    width: 50%;
  }
`;

const Styled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  height: 120px;
  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `};
  cursor: pointer;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  opacity: 0.5;
  color: white;
`;

const Title = styled.div`
  text-align: center;
  margin-top: 8px;
  color: white;
  font-size: 13px;
`;

const AbsMove = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: move;
`;

const AbsDelete = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Inactive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const truncateTitle = title => (title.length > 50 ? `${title.substring(0, 50)}...` : title);

const DayTile = ({ day, index, onDaySelect, onDayToggleActive, disabled }) => {
  let color = theme.colors.orange;
  if (index > 4) color = theme.colors.maroon;
  if (index > 9) color = theme.colors.purple;

  return (
    <Wrapper>
      <Styled color={color} onClick={() => day.is_active && onDaySelect(day, index)}>
        {day.is_active ? (
          <>
            <Label>Day {index + 1}</Label>
            <Title>{truncateTitle(day.title)}</Title>
          </>
        ) : (
          <Inactive onClick={() => onDayToggleActive(index)}>
            <Label>＋ Add New</Label>
          </Inactive>
        )}
      </Styled>
      {day.is_active && !disabled && (
        <>
          <AbsMove className="move-handle">
            <BiMove size={16} color="#ffffff75" />
          </AbsMove>
          <AbsDelete className="move-handle" onClick={() => onDayToggleActive(index)}>
            <BiTrash size={16} color="#ffffff75" />
          </AbsDelete>
        </>
      )}
    </Wrapper>
  );
};

export default DayTile;
