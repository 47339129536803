import React from 'react';
import { MdWarning } from 'react-icons/md';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { DEFAULT_DAYS, DEFAULT_TITLE } from 'helpers/data';
import { theme } from 'styles/theme';
import { fetchURL } from 'helpers/constants';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import 'bootstrap/dist/css/bootstrap.min.css';
import arrayMove from 'array-move';
import update from 'immutability-helper';
import DayTile from './DayTile';
import DayModal, { Body, Styled as StyledModal } from './DayModal';
import Modal from 'components/Modal';
import { getProjectUrlFromId } from 'helpers/utils';
import { Button, Col, Form, Input, Label, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import ShareModal from 'components/ShareModal';

const Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DaysWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

class ProjectGrid extends React.Component {
  state = {
    title: DEFAULT_TITLE,
    days: DEFAULT_DAYS,
    selectedDay: null,
    redirectingToNewProject: false,
  };

  componentDidMount() {
    const { project } = this.props;
    let days = DEFAULT_DAYS;
    let title = DEFAULT_TITLE;

    if (!!project) {
      if (!!project.days_data) days = project.days_data;
      if (!!project.title) title = project.title;
    }
    this.setState({
      days: days,
      didLoad: true,
      title,
      communityPartner: project ? project.community_partner : null,
      problemStatement: project ? project.problem_statement : null,
      coTeachers: project ? project.co_teachers : null,
      studentGradeSubject: project ? project.student_grade_subject : null,
      projectStartDate: project ? project.project_start_date : null,
      projectEndDate: project ? project.project_end_date : null,
      shareOutDates: project ? project.share_out_dates : null,
      fieldResearchDates: project ? project.field_research_dates : null,
      finalPresentationDatetime: project ? project.final_presentation_datetime : null,
    });
  }

  onDaySelect = (day, index) => {
    this.setState({ selectedDay: day, selectedDayIndex: index });
  };

  onCloseModal = () => {
    const { selectedDay, selectedDayIndex } = this.state;
    this.setState(
      { days: update(this.state.days, { [selectedDayIndex]: { $set: selectedDay } }), selectedDay: null, selectedDayIndex: 0 },
      () => this.onSave(),
    );
  };

  onDayToggleActive = index => {
    const day = this.state.days[index];
    const isActive = !day.is_active;
    if (!isActive && day.title === "" && day.tasks.length === 0) {
      return this.setState({ days: this.state.days.filter((_, i) => i !== index) }, () => {
        this.onSave();
      });
    }
    this.setState({ days: update(this.state.days, { [index]: { is_active: { $set: isActive } } }) }, () => {
      if (isActive) this.setState({ selectedDay: this.state.days[index], selectedDayIndex: index });
      this.onSave();
    });
  };

  onDayInputChange = (field, value) => {
    this.setState({ selectedDay: update(this.state.selectedDay, { [field]: { $set: value } }) });
  };

  onToggleTaskComplete = index => {
    this.setState({
      selectedDay: update(this.state.selectedDay, {
        tasks: { [index]: { is_complete: { $set: !this.state.selectedDay.tasks[index].is_complete } } },
      }),
    });
  };

  onTaskInputEdit = (index, value) => {
    this.setState({
      selectedDay: update(this.state.selectedDay, {
        tasks: { [index]: { title: { $set: value } } },
      }),
    });
  };

  onAddNewTask = category => {
    this.setState({
      selectedDay: update(this.state.selectedDay, {
        tasks: { $set: this.state.selectedDay.tasks.concat({ category: category, title: '', is_complete: false }) },
      }),
    });
  };

  onDeleteTask = index => {
    const tasksCopy = this.state.selectedDay.tasks.filter((elem, i) => i !== index)
    this.setState({
      selectedDay: update(this.state.selectedDay, {
        tasks: { $set: tasksCopy },
      }),
    });
  };

  onReorderTask = ({ oldIndex, newIndex }) => {
    this.setState({
      selectedDay: update(this.state.selectedDay, {
        tasks: { $set: arrayMove(this.state.selectedDay.tasks, oldIndex, newIndex) },
      }),
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ days: arrayMove(this.state.days, oldIndex, newIndex) }, () => this.onSave());
  };

  onSave = (projectId, redirect = this.props.setActiveProjectId) => {
    if (this.state.redirectingToNewProject) return;

    const {
      days,
      title,
      communityPartner,
      problemStatement,
      coTeachers,
      studentGradeSubject,
      projectStartDate,
      projectEndDate,
      shareOutDates,
      fieldResearchDates,
      finalPresentationDatetime,
    } = this.state;

    fetch(`${fetchURL}/api/v1/projects/${projectId || this.props.project?.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: window.localStorage.getItem('id_token') },
      body: JSON.stringify({
        days,
        title,
        communityPartner,
        problemStatement,
        coTeachers,
        studentGradeSubject,
        projectStartDate,
        projectEndDate,
        shareOutDates,
        fieldResearchDates,
        finalPresentationDatetime,
      }),
    })
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(data => {
        if (!!data.id && redirect) {
          this.setState({ redirectingToNewProject: true });
          return window.location.href = getProjectUrlFromId(data.id);
        }
        this.props.project.title = title;
        this.props.refreshNavigationState();
      });
  };

  addDay = () => {
    this.setState(prevState => ({
      days: [...prevState.days, {
        tasks: [],
        title: '',
        is_active: true,
      }]
    }), () => this.onSave());
  }

  deleteProject = () => {
    if (!window.confirm('Delete this project?')) return;

    fetch(`${fetchURL}/api/v1/projects/${this.props.project?.id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: window.localStorage.getItem('id_token') },
    })
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(data => {
        window.location.href = '/';
        this.props.refreshNavigationState();
      });
  }

  toggleArchived = () => {
    const action = this.props.archived ? 'unarchive' : 'archive';

    fetch(`${fetchURL}/api/v1/projects/${this.props.project?.id}/${action}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: window.localStorage.getItem('id_token') },
    })
      .then(response => {
        if (response.ok) return response.json();
      })
      .then(data => {
        this.props.project.archived = data.archived;
        this.props.project.share_archived = data.share_archived;
        this.props.refreshNavigationState();
      });
  }

  onTitleInput = (event) => {
    this.setState({ title: event.target.value });
  };


  render() {
    const { editable } = this.props;
    const { days, selectedDay, selectedDayIndex, didLoad, title } = this.state;

    const projectDetailsIncomplete = (
      !this.state.communityPartner ||
      !this.state.problemStatement ||
      !this.state.coTeachers ||
      !this.state.studentGradeSubject ||
      !this.state.projectStartDate ||
      !this.state.projectEndDate ||
      !this.state.shareOutDates ||
      !this.state.fieldResearchDates ||
      !this.state.finalPresentationDatetime
    );

    if (!didLoad) return null;

    return (
      <>
        <div className="d-flex align-items-center">
          <Form className='flex-grow-1' onSubmit={(e) => {e.preventDefault(); this.onSave();}}>
            <Input disabled={!editable} title="title" onChange={this.onTitleInput} onBlur={() => this.onSave()} value={title} className="my-1" />
          </Form>
          <Button id={`project-${this.props.project?.id}-details-incomplete-warning`} className={`flex-shrink-0${ projectDetailsIncomplete && ' text-danger'}`} color="link" onClick={() => this.setState({ showDetailsModal: true })}>
            {
              projectDetailsIncomplete && (
                <UncontrolledTooltip placement="top" target={`project-${this.props.project?.id}-details-incomplete-warning`}>Class details are incomplete</UncontrolledTooltip>
              )
            }
            Class Details
          </Button>
          <Modal isVisible={this.state.showDetailsModal} onClose={() => { this.setState({ showDetailsModal: false }); this.onSave(); } } shouldAlignBottom={window.innerWidth < 800}>
            <StyledModal>
              <ModalHeader className="p-3 pb-0">Important Class Details</ModalHeader>
              <Body>
                <Label>Community Partner</Label>
                <Input
                  value={this.state.communityPartner}
                  onChange={event => this.setState({ communityPartner: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Problem Statement</Label>
                <Input
                  value={this.state.problemStatement}
                  onChange={event => this.setState({ problemStatement: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Co-Teacher(s)</Label>
                <Input
                  value={this.state.coTeachers}
                  onChange={event => this.setState({ coTeachers: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Student Grade/Subject</Label>
                <Input
                  value={this.state.studentGradeSubject}
                  onChange={event => this.setState({ studentGradeSubject: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Project Start Date</Label>
                <Input
                  value={this.state.projectStartDate}
                  onChange={event => this.setState({ projectStartDate: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="date"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Project End Date</Label>
                <Input
                  value={this.state.projectEndDate}
                  onChange={event => this.setState({ projectEndDate: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="date"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Share-Out Dates</Label>
                <Input
                  value={this.state.shareOutDates}
                  onChange={event => this.setState({ shareOutDates: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Field Research Dates</Label>
                <Input
                  value={this.state.fieldResearchDates}
                  onChange={event => this.setState({ fieldResearchDates: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
                <Label className="mt-2">Final Presentation Dates</Label>
                <Input
                  value={this.state.finalPresentationDatetime}
                  onChange={event => this.setState({ finalPresentationDatetime: event.target.value })}
                  placeholder=""
                  fullWidth
                  name="title"
                  type="text"
                  wrapperPadding="0px"
                  fontSize="13px"
                  disabled={!editable}
                />
              </Body>
            </StyledModal>
          </Modal>
        </div>
        <Styled>
          <SortableList
            days={days}
            onSortEnd={this.onSortEnd}
            onDaySelect={this.onDaySelect}
            onDayToggleActive={this.onDayToggleActive}
            axis="xy"
            distance={1}
            disabled={!editable}
          />

          {!!selectedDay && (
            <DayModal
              selectedDay={selectedDay}
              selectedDayIndex={selectedDayIndex}
              onClose={this.onCloseModal}
              onToggleTaskComplete={this.onToggleTaskComplete}
              onTaskInputEdit={this.onTaskInputEdit}
              onAddNewTask={this.onAddNewTask}
              onDeleteTask={this.onDeleteTask}
              onDayInputChange={this.onDayInputChange}
              onReorderTask={this.onReorderTask}
              disabled={!editable}
            />
          )}
        </Styled>
        {(this.props.project?.id && this.props.project.id !== 'add-new-project') && (
          <Row className="justify-content-end w-100 mx-0">
            <Col className="flex-grow-1 px-0 py-0">
              <Button id="add-day-button" outline size="sm" color="link" onClick={this.addDay}>Add New Day</Button>
            </Col>
            {editable && (
              <Col className="flex-grow-0 px-0 py-0">
                <ShareModal projectId={this.props.project.id} />
              </Col>
            )}
            <Col className="flex-grow-0 px-0 py-0">
              <Button id="archive-button" outline size="sm" color="link" onClick={this.toggleArchived}>{this.props.archived ? 'Unarchive' : 'Archive'}</Button>
              <UncontrolledTooltip placement="top" target="archive-button" delay={{ show: 0, hide: 0 }}>Moves this project from <em>Current</em> folder to <em>Archive</em> folder to save it for future reference</UncontrolledTooltip>
            </Col>
            {editable && (
              <Col className="flex-grow-0 px-1 py-0">
                <Button id="delete-button" outline size="sm" color="link" onClick={this.deleteProject}>Delete</Button>
                <UncontrolledTooltip placement="top" target="delete-button" delay={{ show: 0, hide: 0 }}>Deletes this Project file. You will lose your work and it cannot be reversed</UncontrolledTooltip>
              </Col>
            )}
            <Col className="flex-grow-0 px-0 py-0">
              <Button id="duplicate-button" outline size="sm" color="link" onClick={() => this.onSave(-1, true)}>Duplicate</Button>
              <UncontrolledTooltip placement="top" target="duplicate-button" delay={{ show: 0, hide: 0 }}>Makes a copy of this Project in the <em>Current</em> folder</UncontrolledTooltip>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const SortableItem = SortableElement(({ value }) => <>{value}</>);

const SortableList = SortableContainer(({ days, onDaySelect, onDayToggleActive, disabled }) => {
  return (
    <DaysWrapper>
      {days.map((day, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          day={day}
          onDaySelect={onDaySelect}
          value={<DayTile day={day} index={index} onDaySelect={onDaySelect} onDayToggleActive={onDayToggleActive} disabled={disabled} />}
          disabled={disabled}
        />
      ))}
    </DaysWrapper>
  );
});

export default ProjectGrid;
