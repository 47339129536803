import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme } from 'styles/theme';
import Input from 'components/Input';
import Button from 'components/Button';

const Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: ${theme.colors.gray};
  padding: 50px;
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`;

const Inner = styled.div`
  width: 400px;
  max-width: 100%;
`;

const Title = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
`;

const Join = ({ loginEmail, onEmailChange, onLoginSubmit, isLoginLoading }) => (
  <Styled>
    <Inner>
      <Title>Login or Sign Up</Title>
      <Input
        value={loginEmail}
        onChange={e => onEmailChange(e.target.value)}
        placeholder="Email"
        fullWidth
        name="email"
        type="text"
        wrapperPadding="0px"
        fontSize="13px"
      />
      <Button
        text={isLoginLoading ? 'Loading...' : 'Continue'}
        disabled={isLoginLoading}
        auto
        mdButton
        autoWidth
        useBlueGradient
        style={{ marginTop: 12 }}
        onClick={onLoginSubmit}
      />
    </Inner>
  </Styled>
);

export default Join;
