import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { theme, lgBorderRadius } from 'styles/theme';
import { createPortal } from 'react-dom';
import { useLockBodyScroll } from 'hooks/useLockBodyScroll';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import CircleIcon from 'components/CircleIcon';

ReactModal.setAppElement('#modal-root');

const StyledModal = styled(ReactModal)``;

const ModalContainer = styled.div`
  background: white;
  ${lgBorderRadius};
  ${({ noBackground }) =>
    noBackground &&
    css`
      background: transparent;
    `};
  ${({ noBorderRadiusMobile }) =>
    noBorderRadiusMobile &&
    css`
      @media screen and (max-width: 800px) {
        border-radius: 0px;
      }
    `};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  ${({ isCloseButtonLeft }) =>
    isCloseButtonLeft &&
    css`
      @media screen and (min-width: 800px) {
        right: auto;
        left: 16px;
      }
    `};
`;

const Modal = ({
  isVisible,
  onClose,
  children,
  noBackground,
  noBorderRadiusMobile,
  showClose,
  extraOuterComponent,
  isCloseButtonLeft,
  showCloseMobileOnly,
  showCloseDesktopOnly,
  shouldAlignBottom,
  style = {},
}) => {
  // useLockBodyScroll(isVisible);
  return createPortal(
    <StyledModal isOpen={isVisible} onRequestClose={onClose} style={shouldAlignBottom ? alignBottomStyles : primaryStyles}>
      {showClose && (
        <CloseButton isCloseButtonLeft={isCloseButtonLeft} className={showCloseMobileOnly ? 'mobile' : showCloseDesktopOnly && 'desktop'}>
          <CircleIcon icon={<FiX size={18} />} onClick={onClose} />
        </CloseButton>
      )}
      <ModalContainer noBackground={noBackground} noBorderRadiusMobile={noBorderRadiusMobile}>
        {children}
      </ModalContainer>
    </StyledModal>,
    document.getElementById('modal-root'),
  );
};

export default Modal;

const primaryStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'auto',
    height: 'auto',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'transparent',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '0px',
    outline: 'none',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const alignBottomStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'auto',
    height: 'auto',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'transparent',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '0px',
    outline: 'none',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'none',
    padding: 0,
    border: '0px solid',
    borderRadius: '16px 16px 0 0',
    background: 'white',
  },
};
